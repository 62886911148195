import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import { SubTitle, Title, Paragraph } from "../components/text";
import Input from "../components/input";
import TextArea from "../components/textarea";
import { Button, LinkButton } from "../components/button/button";

export const ServiceTemplate = ({
  content,
  contentComponent,
  description,
  title,
  image,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  // console.warn(image)
  return (
    <Page>
      <FullWidthImage image={image} height="50vh" parallax={50} dim={0.6}>
        <Title bright marginBottom>
          {title}
        </Title>
        <Paragraph bright>{description}</Paragraph>
      </FullWidthImage>
      <Section>
        {helmet || ""}
        <PostContent content={content} className="content" />
      </Section>

      <LinkButton
        to="/terms"
        title="View Terms & Conditions"
        center
        marginBottom
      />
    </Page>
  );
};

export default (props) => {
  const { markdownRemark: post } = props.data;

  return (
    <ServiceTemplate
      content={post.html}
      contentComponent={HTMLContent}
      description={post.frontmatter.description}
      helmet={<Helmet title={`Wesmans | ${post.frontmatter.title}`} />}
      title={post.frontmatter.title}
      image={post.frontmatter.image}
    />
  );
};

export const pageQuery = graphql`
  query ServiceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`;

// <Section title="Contact us">
//   <form
//     name={ `contact-${ title.toLowerCase() }` }
//     method="POST"
//     action="thank-you"
//     data-netlify="true"
//     data-netlify-honeypot="bot-field"
//   >
//     <input type="hidden" name="form-name" value="contact" />
//     <Input
//       label="Your name"
//       type="text"
//       name="name"
//       placeholder="Enter your name"
//     />
//     <Input
//       label="Your email"
//       type="email"
//       name="email"
//       placeholder="Enter your email"
//     />
//     <TextArea
//       label="Message"
//       name="message"
//       placeholder="Enter your message"
//     />
//     <div data-netlify-recaptcha></div>
//     <Button
//       title="Submit"
//       type="submit"
//       marginTop
//     />
//   </form>
// </Section>
